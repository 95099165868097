// hooks
import React from "react";
import { useForm, Controller } from "react-hook-form";
import { useMutation, useQuery } from "react-query";

// conponents
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Toolbar } from "primereact/toolbar";
import { Button } from "primereact/button";
import { Link, useNavigate, useParams } from "react-router-dom";
import toast from "react-hot-toast";

// config

// api related
import classNames from "classnames";
import Api from "../../api/Api";
import { useGetRole } from "../../hook/role.hook";
import { useState } from "react";
import { useEffect } from "react";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import UpdateAddressModal from "../../components/user/UpdateAddressModal";
import { Dialog } from "primereact/dialog";
import formatRupiah from "../../utils/formatRupiah";

const UpdateUser = ({ permissions }) => {
  const navigate = useNavigate();
  const { id } = useParams();

  // hooks
  const { control, handleSubmit, formState, reset } = useForm();

  // state
  const [globalFilter, setGlobalFilter] = useState(null);
  const [selectItems, setSelectItems] = useState([]);
  const [showDeleteItemModal, setShowDeleteItemModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [address, setAddress] = useState([]);
  const [tempUserAddress, setTempUserAddress] = useState({});

  // query
  const { data: roleOptions } = useGetRole({ filter_super_admin: false });
  const { isLoading: loadingUser } = useQuery(["user", id], async () => await getUserDetails(), { defaultValue: [] });
  const { data: memberOptions } = useQuery(["members"], async () => await getMemberOptions(), { defaultValue: [] });

  const { isLoading: createLoading, mutate: crateMutate } = useMutation(async (data) => await Api().post("/user/edit", data), {
    onSettled: (response) => {
      if (response.data.status === 200) {
        navigate("/dashboard/users");
        toast.success("User Updated", { duration: 5000 });
      } else {
        toast.error(response.data.message, { duration: 5000 });
      }
    },
  });
  const { isLoading: loadingGetAddress, mutate: getUserAddress } = useMutation(async (data) => await Api().post("/user/addresses", data), {
    onSettled: (response, error) => {
      if (error) {
        return toast.error("Gagal mendapatkan address user");
      }

      if (response.data.status !== 200) {
        return toast.error(response.data.message);
      }

      setAddress(response.data.data);
    },
  });
  const { isLoading: deleteLoading, mutate: deleteMutate } = useMutation(async (data) => await Api().delete("/user/address", { data: data }), {
    onSettled: (response, error) => {
      if (response.data.status === 200) {
        setSelectItems([]);
        getUserAddress({ user_id: id });
        setShowDeleteItemModal(false);
        toast.success("Address Deleted", { duration: 4000 });
      } else {
        setSelectItems([]);
        getUserAddress({ user_id: id });
        setShowDeleteItemModal(false);
        toast.error(response.data.message, { duration: 5000 });
      }
    },
  });

  // functions
  const onSubmit = (data) => {
    delete data.images;
    delete data.password;

    crateMutate({ ...data, user_id: data._id });
  };

  const getUserDetails = async () => {
    const response = await Api().get(`/user/detail/${id}`);
    if (response.data.status !== 200) {
      return toast.error(response.data.message);
    }

    reset(response.data.data);
    return response.data.data;
  };
  const getMemberOptions = async () => {
    const response = await Api().get(`/member`);
    if (response.data.status !== 200) {
      return toast.error(response.data.message);
    }

    return response.data.data;
  };

  const showDeleteItemConfirmation = (data) => {
    setSelectItems({
      name: data.name,
      address_id: data._id,
      user_id: id,
    });
    setShowDeleteItemModal(true);
  };

  const confirmDeleteItem = () => {
    let payload = {
      address_id: selectItems.address_id,
      user_id: id,
    };

    deleteMutate(payload);
  };

  const editHandler = async (data) => {
    setTempUserAddress(data);
    setShowEditModal(true);
  };

  useEffect(() => {
    getUserAddress({
      user_id: id,
    });
  }, [id, getUserAddress]);

  // components
  const leftToolbar = () => {
    return (
      <React.Fragment>
        <div className="col-12">
          <h4 className="uppercase" style={{ margin: 0 }}>
            Update User
          </h4>
        </div>
      </React.Fragment>
    );
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <div className="actions">
        <Button icon="pi pi-pencil" className="p-button-rounded p-button-warning mr-2" onClick={() => editHandler(rowData)} />
        <Button icon="pi pi-trash" className="p-button-rounded p-button-danger mt-2" onClick={() => showDeleteItemConfirmation(rowData)} />
      </div>
    );
  };

  const header = () => {
    return (
      <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
        <h5 className="m-0">User Address</h5>
        <span className="block mt-2 md:mt-0 p-input-icon-left">
          <i className="pi pi-search" />
          <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
        </span>
      </div>
    );
  };

  const deleteSingleItemFooter = () => {
    return (
      <>
        <Button label="No" icon="pi pi-times" className="p-button-text" onClick={() => setShowDeleteItemModal(false)} />
        <Button label="Yes" loading={deleteLoading} icon="pi pi-check" className="p-button-text" onClick={confirmDeleteItem} />
      </>
    );
  };

  return (
    <>
      <div className="grid crud-demo">
        <div className="col-12">
          <form onSubmit={handleSubmit(onSubmit)} style={{ borderRadius: "0" }} className="card">
            <Toolbar className="mb-4" left={leftToolbar} />
            <div className="p-fluid formgrid grid">
              <div className="field col-12">
                <label htmlFor="name">Name: </label>
                <Controller
                  rules={{ required: true }}
                  control={control}
                  defaultValue={""}
                  id="name"
                  name="name"
                  render={({ field }) => <InputText placeholder="input name" value={field.value} onBlur={field.onBlur} ref={field.ref} onChange={(e) => field.onChange(e)} id="name" type="text" className={formState.errors.name && "p-invalid"} />}
                />
                {formState.errors.name && (
                  <small id="name" className="p-error block pt-1">
                    field required
                  </small>
                )}
              </div>

              <div className="field col-12">
                <label htmlFor="email">E-Mail: </label>
                <Controller
                  rules={{ required: true }}
                  control={control}
                  defaultValue={""}
                  id="email"
                  name="email"
                  render={({ field }) => <InputText placeholder="Input email" value={field.value} onBlur={field.onBlur} ref={field.ref} onChange={(e) => field.onChange(e)} id="email" type="text" className={formState.errors.name && "p-invalid"} />}
                />
                {formState.errors.name && (
                  <small id="name" className="p-error block pt-1">
                    field required
                  </small>
                )}
              </div>
              <div className="field col-12">
                <label htmlFor="phone_number">Phone Number : </label>
                <Controller
                  rules={{ required: true }}
                  control={control}
                  defaultValue={""}
                  id="phone_number"
                  name="phone_number"
                  render={({ field }) => (
                    <InputText
                      placeholder="Input phone number"
                      value={field.value}
                      onBlur={field.onBlur}
                      ref={field.ref}
                      onChange={(e) => field.onChange(e)}
                      id="phone_number"
                      type="text"
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      className={formState.errors.phone_number && "p-invalid"}
                    />
                  )}
                />
                {formState.errors.phone_number && (
                  <small id="phone_number" className="p-error block pt-1">
                    Field required
                  </small>
                )}
              </div>
              <div className="field col-12 ">
                <label htmlFor="choose-kurir">Role :</label>
                <Controller
                  control={control}
                  defaultValue={""}
                  name="role_id"
                  render={({ field }) => (
                    <Dropdown
                      ref={field.ref}
                      optionLabel="name"
                      optionValue="_id"
                      disabled={!roleOptions?.length}
                      value={field.value}
                      onBlur={field.onBlur}
                      options={roleOptions}
                      className={classNames({
                        "p-invalid": formState.errors.role_id,
                      })}
                      onChange={(e) => {
                        field.onChange(e);
                      }}
                      placeholder="Pilih Role"
                    />
                  )}
                />
                {formState.errors.role_id && (
                  <small id="choose-kurir" className="p-error block pt-1">
                    You dont have any role yet
                  </small>
                )}
              </div>
              <div className="field col-12 ">
                <label htmlFor="choose-kurir">Member :</label>
                <Controller
                  control={control}
                  defaultValue={""}
                  name="member_id"
                  render={({ field }) => (
                    <Dropdown
                      ref={field.ref}
                      optionLabel="name"
                      optionValue="_id"
                      disabled={!memberOptions?.length}
                      value={field.value}
                      onBlur={field.onBlur}
                      options={memberOptions}
                      onChange={(e) => {
                        field.onChange(e);
                      }}
                      placeholder="Select member"
                    />
                  )}
                />
              </div>

              <div className="field col-12">
                <label htmlFor="name">Total Spending: </label>
                <Controller
                  rules={{ required: true }}
                  control={control}
                  defaultValue={""}
                  name="spending_amount"
                  render={({ field }) => <InputText disabled placeholder="input name" value={formatRupiah(field.value || 0)} onBlur={field.onBlur} ref={field.ref} id="name" type="text" className={formState.errors.name && "p-invalid"} />}
                />
              </div>

              <div className="w-full flex justify-content-center mt-4">
                <div className="col-6 md:col-3 xl:col-2">
                  <Button disabled={loadingUser} label="Save" loading={createLoading} className=" p-button-primary mr-4" />
                </div>
                <div className="col-6 md:col-3 xl:col-2">
                  <Link to="/dashboard/users">
                    <Button type="button" label="Back" className=" p-button-secondary" />
                  </Link>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>

      <div className="grid crud-demo">
        <div className="col-12">
          <div className="card col-12 mx-auto">
            <DataTable
              loading={loadingGetAddress}
              value={address}
              selection={selectItems}
              onSelectionChange={(e) => setSelectItems(e.value)}
              dataKey="_id"
              paginator
              rows={10}
              rowsPerPageOptions={[5, 10, 25]}
              className="datatable-responsive"
              paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
              currentPageReportTemplate="Menampilkan {first} ke {last} dari {totalRecords}"
              globalFilter={globalFilter}
              emptyMessage="No data found."
              header={header}
              responsiveLayout="scroll"
            >
              <Column field="name" header="Address Name" sortable headerStyle={{ width: "20%", minWidth: "10rem" }}></Column>
              <Column field="city" header="City" sortable headerStyle={{ width: "auto", minWidth: "10rem" }}></Column>
              <Column field="postal_code" header="Postal Code" sortable headerStyle={{ width: "10%", minWidth: "10rem" }}></Column>
              <Column body={(data) => (data.active_status ? "Active" : "Inactive")} field="active_status" header="Status" sortable headerStyle={{ width: "10%", minWidth: "10rem" }}></Column>
              <Column header="Actions" body={actionBodyTemplate} headerStyle={{ width: "10%", minWidth: "10rem" }}></Column>
            </DataTable>
          </div>
        </div>
      </div>

      <Dialog visible={showDeleteItemModal} className="modal-container" header="Confirm" modal footer={deleteSingleItemFooter} onHide={() => setShowDeleteItemModal(false)}>
        <div className="flex align-items-center  mb-4">
          <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: "2rem" }} />
          <span>
            Are you sure you want to delete <b>{selectItems?.name}</b>?
          </span>
        </div>
      </Dialog>
      <UpdateAddressModal
        user_id={id}
        show={showEditModal}
        setShow={setShowEditModal}
        value={tempUserAddress}
        refetch={() =>
          getUserAddress({
            user_id: id,
          })
        }
      />
    </>
  );
};

const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location?.path === nextProps.location?.path;
};

export default React.memo(UpdateUser, comparisonFn);
